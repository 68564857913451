<template>
  <div class="answer-list">
    <!-- 题库 -->
    <el-form :form="form" inline>
      <el-form-item label="题干">
        <el-input v-model="form.questionTitle"></el-input>
      </el-form-item>
      <el-form-item label="试题类型">
        <el-select v-model="form.questionTypeId" clearable>
          <el-option
            v-for="(item, index) in typeOptions"
            :key="index"
            :label="item.typeName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" @click="query">查询</el-button>
        <el-button type="primary" @click="confirm">确认</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      @current-change="currentChange"
      highlight-current-row
      :height="400"
    >
      <el-table-column label="类型" prop="typeName">
      </el-table-column>
      <el-table-column label="题干" prop="questionTitle">
        <template slot-scope="scope">
          <div v-html="scope.row.questionTitle"></div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        questionTitle: null, //题干
        questionTypeId: null, // 试题类型
      },
      typeOptions: [],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
      rows: null, // 选中
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    }
  },
  methods: {
    confirm() {
      if (!this.rows) {
        return this.$message.error("请选择数据");
      }
      this.$emit("rows", this.rows);
      this.rows = null;
    },
    init(){
      this.openLoadingView();
      this.$axios_supermallData({
        url:"/hnjxjy-core/eduCourseQuestion/queryEduCourseByTeaId",
        method: 'get',
        params: {
          courseId: this.course.id,
          page: this.current,
          pageSize : this.pageSize
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch((e)=>{
        this.$message.error(e)
        this.loadingView.close();
      });
    },
    initTypeOptions(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseQuestion/selectEduQuestionTypeList")
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.typeOptions = resp.data.data;
            }
          });
      this.loadingView.close();
    },
    query() {
      this.openLoadingView();
      if (this.form.questionTitle == ""){
        this.form.questionTitle = null;
      }
      if (this.form.questionTypeId == ""){
        this.form.questionTypeId = null;
      }
      this.$axios_supermallData({
        url:"/hnjxjy-core/eduCourseQuestion/queryEduCourseByTeaId",
        method: 'get',
        params: {
          courseId: this.course.id,
          page: this.current,
          pageSize : this.pageSize,
          questionTypeId: this.form.questionTypeId,
          questionTitle: this.form.questionTitle
        }
      }).then((resp) => {
            if (resp.data.code == 2000) {
              this.tableData = resp.data.data.list;
            }
          });
      this.loadingView.close();
    },
    // 选中数据
    currentChange(row) {
      if (row) this.rows = row;
    },
    handleSizeChange(pageSize){
      if (pageSize) {
        this.pageSize = pageSize;
        this.query();
      }
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.query();
      }
    },
  },
};
</script>

<style>
</style>
