

<style scoped lang="less">
.rootBtn {
  margin: 5px 0;
}
.t_course_manage_edit {
  box-shadow: @shadow;
  background: #fff;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    .items {
      .parent {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .parent_input {
          margin-right: 10px;
        }
      }
      .el-input {
        width: 360px;
      }
    }
    .add_moudle {
      display: flex;
      justify-content: center;
      width: 50%;
      margin: 15px auto 0;
      max-width: 600px;
      .el-input {
        margin-right: 10px;
      }
    }
    .add_theme {
      display: flex;
      justify-content: center;
      width: 50%;
      margin: 0 auto;
      max-width: 600px;
      .el-input {
        margin-right: 10px;
      }
    }
  }
}
</style>
<style lang="less">
.t_course_manage_edit {
  .content {
    .el-collapse {
      border: 0;
      .el-collapse-item__header {
        border: 0;
        max-width: 90%;
        margin: 10px auto;
        justify-content: center;
        display: flex;
        align-items: center;
        p {
          // width: 50%;
          width: 360px;
          margin-right: 10px;
          .el-input {
            width: 100%;
          }
        }
        .success_text {
          color: #67c23a;
        }
        .el-button:nth-child(3) {
          margin-right: 10px;
        }
        .el-collapse-item__arrow {
          margin: 0;
          margin-left: 10px;
          font-size: 20px;
        }
      }
      .collapse_content {
        .collapse_content_item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 15px;
          .el-input {
            max-width: 300px;
          }
          .el-tag {
            margin: 0 10px;
          }
        }
        .collapse_content_item:last-child {
          margin-bottom: 0;
        }
      }
      .el-collapse-item__wrap {
        border: 0;
        .el-collapse-item__content {
          padding: 0;
        }
      }
    }
  }
  .t_module_course_manage_edit_raido {
    .el-radio,
    .el-radio__input {
      white-space: unset;
    }
  }
}
</style>
<template>
  <div class="t_course_manage_edit">
    <div class="stu-module-header">
      <div class="stu-module-title">课程管理-编辑</div>
      <div>
        <el-button type="primary" @click="save">保存</el-button>
        <el-button
          type="primary"
          @click="add"
          >添加模块</el-button
        >
        <el-button type="primary" @click="back">返回</el-button>
      </div>
    </div>
    <div class="content">
      <div class="items" v-for="(item, index) in data" :key="index" v-if="course && T0 === course.courseType">
        <div class="parent">
          <el-input
            v-model="item.chapterName"
            class="parent_input"
            :maxlength="100"
            size="medium"
            show-word-limit
          ></el-input>
          <el-button size="medium" type="primary" plain @click="control(item)">
            过程控制
          </el-button>
          <el-button
            size="medium"
            type="success"
            @click="addNodes(item, index)"
          >
            添加主题
          </el-button>
          <el-button size="medium" type="text" @click="delNodes(item)">
            删除
          </el-button>
        </div>
        <el-collapse>
          <el-collapse-item
            v-for="(child, cindex) in item.childList"
            :key="cindex"
          >
            <template slot="title">
              <p @click.stop.prevent="() => {}">
                <el-input
                  v-model="child.chapterName"
                  :maxlength="100"
                  size="medium"
                  show-word-limit
                ></el-input>
              </p>
              <el-button
                type="text"
                style="margin: 0 10px"
                class="success_text"
                @click.stop.prevent="saveChild(child, cindex, index)"
              >
                保存
              </el-button>
              <el-dropdown
                trigger="click"
                style="width: 70px; text-align: center; margin-right: 10px"
                @command="command"
              >
                <span class="success_text" @click.stop.prevent="() => {}">
                  添加
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-if="course && T0 === course.courseType"
                      :command="{ name: '课程资源', data: child }"
                    >课程资源</el-dropdown-item
                  >

                  <el-dropdown-item
                      v-if="course && T0 !== course.courseType"
                      :command="{ name: '网盘资源', data: child }"
                  >网盘资源</el-dropdown-item
                  >

                  <el-dropdown-item :command="{ name: '外部链接', data: child }"
                    >外部链接</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-if="course && T0 === course.courseType"
                    :command="{ name: '在线作业', data: child }"
                    >在线作业</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                type="text"
                @click.stop.prevent="delAllChild(child, cindex, index)"
              >
                删除
              </el-button>
            </template>

            <div class="collapse_content">
              <div
                class="collapse_content_item"
                v-for="(c, i) in child.childList"
                :key="i"
              >
                <el-input
                  v-model="c.chapterName"
                  :maxlength="100"
                  size="medium"
                  show-word-limit
                ></el-input>
                <el-tag type="info" effect="plain" size="mini">
                  <span v-if="c.teachingType == 0">视频</span>
                  <span v-if="c.teachingType == 1">图片</span>
                  <span v-if="c.teachingType == 2">作业</span>
                  <span v-if="c.teachingType == 3">链接</span>
                  <span v-if="c.teachingType == 4">其它</span>
                </el-tag>
                <el-button
                  type="text"
                  @click="instantProblemSave(c)"
                  style="color: #4eba46; width: 72px; height: 32px"
                  v-if="c.teachingType === 0 && course && T0 === course.courseType"
                  >添加即时题</el-button
                >
                <el-button
                  type="text"
                  style="width: 72px; height: 32px"
                  v-if="c.teachingType !== 0"
                ></el-button>

                <el-button
                    type="text"
                    style="width: 72px; height: 32px"
                    v-if="course && T0 !== course.courseType"
                ></el-button>

                <el-button type="text" @click="delChild(c)">删除</el-button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>


      <div class="items" v-if="course && T0 !== course.courseType">
        <el-collapse>
          <el-collapse-item
              v-for="(child, cindex) in data"
              :key="cindex"
          >
            <template slot="title">
              <p @click.stop.prevent="() => {}">
                <el-input
                    v-model="child.chapterName"
                    :maxlength="100"
                    size="medium"
                    show-word-limit
                ></el-input>
              </p>
              <el-dropdown
                  trigger="click"
                  style="width: 70px; text-align: center; margin-right: 10px"
                  @command="command"
              >
                <span class="success_text" @click.stop.prevent="() => {}">
                  添加
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                      v-if="course && T0 === course.courseType"
                      :command="{ name: '课程资源', data: child }"
                  >课程资源</el-dropdown-item
                  >

                  <el-dropdown-item
                      v-if="course && T0 !== course.courseType"
                      :command="{ name: '网盘资源', data: child }"
                  >网盘资源</el-dropdown-item
                  >

                  <el-dropdown-item :command="{ name: '外部链接', data: child }"
                  >外部链接</el-dropdown-item
                  >
                  <el-dropdown-item
                      v-if="course && T0 === course.courseType"
                      :command="{ name: '在线作业', data: child }"
                  >在线作业</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <el-button
                  type="text"
                  @click.stop.prevent="delAllChild(child, cindex, index)"
              >
                删除
              </el-button>
            </template>

            <div class="collapse_content">
              <div
                  class="collapse_content_item"
                  v-for="(c, i) in child.childList"
                  :key="i"
              >
                <el-input
                    v-model="c.chapterName"
                    :maxlength="100"
                    size="medium"
                    show-word-limit
                ></el-input>
                <el-tag type="info" effect="plain" size="mini">
                  <span v-if="c.teachingType == 0">视频</span>
                  <span v-if="c.teachingType == 1">图片</span>
                  <span v-if="c.teachingType == 2">作业</span>
                  <span v-if="c.teachingType == 3">链接</span>
                  <span v-if="c.teachingType == 4">其它</span>
                </el-tag>
                <el-button
                    type="text"
                    @click="instantProblemSave(c)"
                    style="color: #4eba46; width: 72px; height: 32px"
                    v-if="c.teachingType === 0 && course && T0 === course.courseType"
                >添加即时题</el-button>
                <el-button
                    type="text"
                    style="width: 32px; height: 32px"
                    v-else
                ></el-button>

                <!-- <el-button
                    type="text"
                    style="width: 72px; height: 32px"
                    v-if="course && T0 !== course.courseType"
                ></el-button> -->

                <el-button type="text" @click="delChild(c)">删除</el-button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>



      <div class="add_moudle" v-if="addModuleShow">
        <el-input
          v-model="addTitle"
          :maxlength="100"
          size="medium"
          show-word-limit
        ></el-input>
        <el-button @click="addModuleSave" type="primary" size="medium">
          保存
        </el-button>
        <el-button @click="addModuleShow = false" size="medium">取消</el-button>
      </div>
    </div>

    <!-- 过程控制 -->
    <el-dialog :visible.sync="controlItem.dialog" title="过程控制" width="40%">
      <el-radio-group
        v-model="controlItem.radio"
        class="t_module_course_manage_edit_raido"
      >
        <el-radio label="0">无过程控制</el-radio>
        <el-radio label="1">
          模块学习进度达
          <el-input-number
            v-model="controlItem.inputNumber"
            :min="1"
            :max="1000"
          ></el-input-number>
          %方可学习下一模块
        </el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="controlCancel()">取 消</el-button>
        <el-button type="primary" @click="controlSave"> 确 定 </el-button>
      </span>
    </el-dialog>

    <!-- 课程资源 -->
    <el-dialog :visible.sync="resourceItem.dialog" title="课程资源" width="50%">
      <el-input
        v-model="resourceItem.search"
        placeholder="请输入"
        style="width: 200px; margin-right: 10px"
        clearable
      >
      </el-input>
      <el-button type="primary" @click="resourceQuery">查询</el-button>
      <el-table
        :data="resourceItem.tableData"
        :height="250"
        style="margin-top: 10px"
        @selection-change="selectionChange"
      >
        <el-table-column
          type="selection"
          align="center"
          width="65"
        ></el-table-column>
        <el-table-column
          prop="resourceName"
          label="文件名称"
          min-width="120"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="labelName"
          align="center"
          label="标签"
        ></el-table-column>
        <el-table-column
          prop="resourceType"
          align="center"
          label="文件类型"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.resourceType==1">文档</span>
            <span v-if="scope.row.resourceType==2">音频</span>
            <span v-if="scope.row.resourceType==3">视频</span>
            <span v-if="scope.row.resourceType==4">图片</span>
            <span v-if="scope.row.resourceType==5">网页</span>
            <span v-if="scope.row.resourceType==6">压缩包</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="resourceSize"
          align="center"
          label="文件大小"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          min-width="120"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resourceDialog">取 消</el-button>
        <el-button type="primary" @click="resourceSave"> 确 定 </el-button>
      </span>
    </el-dialog>

    <!-- 外部链接 -->
    <el-dialog :visible.sync="linkItem.dialog" title="外部链接" width="40%">
      <el-form
        :model="linkItem.form"
        label-width="60px"
        :rules="linkItem.rules"
        size="medium"
        ref="linkItemRef"
      >
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="linkItem.form.name"
            placeholder="请输入"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="url">
          <el-input
            v-model="linkItem.form.url"
            placeholder="地址需携带http://或https://"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="linkDialog">取 消</el-button>
        <el-button type="primary" @click="linkSave('linkItemRef')">
          确 定
        </el-button>
      </span>
    </el-dialog>


    <!-- 网盘资源 -->
    <el-dialog :visible.sync="theCloud.dialog" title="网盘资源" width="50%" :before-close="theCloudBeforeClose">
      <el-input
          v-model="theCloud.search"
          placeholder="请输入文件名称"
          style="width: 200px; margin-right: 10px"
          clearable
      >
      </el-input>
      <el-button type="primary" @click="theCloudButton">查询</el-button>
      <div>
        <el-button type="text" @click="handleListRootNav">
          根目录
        </el-button>
        <template v-for="(c, i) in filePathChildData">
          <el-button
              style="marginl-left: 10px"
              :key="i"
              class="rootBtn"
              type="text"
              @click="rootNav(c, i)"
          >
            > {{ c.fileName }}
          </el-button>
        </template>
      </div>
      <el-table
          :data="theCloud.tableData"
          :height="250"
          style="margin-top: 10px"
          @selection-change="theCloudSelectionChange"
          @row-click="actionPFileId"
      >
        <el-table-column
            type="selection"
            align="center"
            width="65"
            :selectable="checkBoxDisable"
        ></el-table-column>
        <el-table-column
            align="center"
            label=""
        >
          <template slot-scope="scope">
            <div v-if="scope.row.fileType === 0" class="folder">
              <svg-icon name="folder" style="width: 120px; height: 40px"></svg-icon>
            </div>
            <div v-if="scope.row.fileType === 1" class="folder">
              <el-image
                  v-if="scope.row.status === 5 || scope.row.status === 6"
                  :src="scope.row.fileUrl"
                  class="fileUrl"
                  style="width: 120px; height: 40px;"
              ></el-image>
              <img v-else style="background-color: #99a9bf; width: 120px; height: 40px;" src="../../../../../assets/logo_top.png" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="fileName"
            align="center"
            label="文件名称"
        ></el-table-column>
        <el-table-column
            align="center"
            label="文件类型"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.fileType === 1">
              <span v-if="scope.row.status === 0">txt</span>
              <span v-if="scope.row.status === 1">ppt</span>
              <span v-if="scope.row.status === 2">docx</span>
              <span v-if="scope.row.status === 3">xlsx</span>
              <span v-if="scope.row.status === 4">pdf</span>
              <span v-if="scope.row.status === 5">图片</span>
              <span v-if="scope.row.status === 6">gif</span>
              <span v-if="scope.row.status === 8">音频</span>
              <span v-if="scope.row.status === 7">视频</span>
            </span>
            <span v-if="scope.row.fileType === 0">文件夹</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 1rem" class="bottom">
        <el-pagination
            @size-change="theCloudSizeChange"
            @current-change="theCloudCurrentChange"
            :current-page="theCloud.page"
            :page-sizes="[5,10,15,20]"
            :page-size="theCloud.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="theCloud.total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="theCloudDialog">取 消</el-button>
        <el-button type="primary" @click="theCloudSave"> 确 定 </el-button>
      </span>
    </el-dialog>


    <el-dialog :visible.sync="onlineItem.dialog" title="在线作业" width="50%" :before-close="onlineItemBeforeClose">
      <el-table
        :data="onlineItem.tableData"
      >
        <el-table-column fixed label="选择">
          <template slot-scope="scope">
            <el-radio
                :label="scope.row"
                v-model="currentRow"
                style="color: #fff; padding-left: 10px; margin-right: -25px"
            >{{''}}</el-radio>
          </template>
        </el-table-column>
        <el-table-column
            label="作业名称"
            prop="workName"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="权重"
            prop="weight"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            label="形考考试时间"
            prop="answerTime"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 30, 50, 100]"
          @size-change="handleSizeChangeDetails"
          @current-change="handleCurrentChangeDetails"
          :page-size="onlineQueryParam.pageSize"
          :current-page="onlineQueryParam.page"
          :total="onlineQueryParamTotal">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onlineItemDialogFalse">取 消</el-button>
        <el-button type="primary" @click="onlineSave"> 确 定 </el-button>
      </span>
    </el-dialog>

    <!-- 即时题 -->
    <el-dialog
      style="z-index: -999"
      :visible.sync="instant.dialog"
      title="添加即时题"
      width="50%"
      :before-close="beforeClose"
    >
      <Instant
        ref="instant"
        v-if="instantOutlineItem != null"
        :instantOutlineItem="instantOutlineItem"
        @data="instantData"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onInstantSave"> 关 闭 </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Instant from "./Instant.vue";
import { COURSE_TYPE } from "@/tools/const";
export default {
  name: "t_course_manage_edit",
  components: { Instant },
  data() {
    return {
      currentRow: null,
      data: [],
      // 过程控制需求id
      controlSaveId: null,
      addModuleShow: false,
      addTitle: null,
      // 过程控制
      controlItem: {
        dialog: false,
        radio: "0",
        inputNumber: 1,
      },
      // 课程资源
      resourceItem: {
        dialog: false,
        search: null,
        selectionData: [],
        tableData: [],
      },
      filePathChildData: [], // 存放点击过的子集合
      theCloud: {
        page: 1,
        pageSize: 5,
        total: 0,
        dialog: false,
        search: null,
        selectionData: [],
        tableData: [],
        pfileId: null,
      },

      // 外部链接
      linkItem: {
        dialog: false,
        form: {
          name: null,
          url: null,
        },
        rules: {
          name: [{ required: true, message: "名称不能为空", trigger: "blur" }],
          url: [{ required: true, message: "域名不能为空", trigger: "blur" }],
        },
      },
      onlineItem: {
        dialog: false,
        row: null,
        tableData: [],
        col: [
          { label: "作业名称", prop: "workName", width: 120 },
          { label: "权重", prop: "weight" },
          { label: "形考考试时间", prop: "answerTime", width: 120 },
        ],
      },
      multipleSelection: [],
      resourcePChapterId: null,
      addNodesId: null,
      instantOutlineItem: null,
      // 即时题
      instant: {
        dialog: false,
      },
      onlineQueryParam: {
        page: 1,
        pageSize: 10,
      },
      onlineQueryParamTotal: 10,
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
    T0() {
      return Number(COURSE_TYPE.T0);
    },
  },
  activated() {
    this.query();
  },
  methods: {
    // 添加模块
    add() {
      this.addModuleShow = true;
    },
    // 添加模块 保存
    addModuleSave() {
      if (null == this.data){
        this.data = [];
      }
      if (undefined == this.data){
        this.data = [];
      }
      this.data.push({
        chapterName: this.addTitle,
        childList: [],
      });
      let formData = new FormData();
      formData.append("courseId", this.course.id);
      formData.append("chapterName", this.addTitle);
      this.openLoadingView();
      this.$axios_supermallData
          .post("/hnjxjy-core/eduCourseOutline/saveModel", formData)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.query();
            }
          });
      this.addTitle = null;
      this.loadingView.close();
      this.addModuleShow = false;
    },
    saveAuxiliary(dataChildList,data){
      if (null != dataChildList && dataChildList.length > 0){
        for (let i = 0 ; i < dataChildList.length ; i++){
          let chapterName = dataChildList[i].chapterName;
          let id = dataChildList[i].id;
          let data2 = {
            chapterName: chapterName,
            id: id
          };
          data.push(data2);
          this.saveAuxiliary(dataChildList[i].childList,data);
        }
      }else{

      }
    },
    save() {
      let data = [];
      for (let i = 0 ; i < this.data.length ; i++){
        let chapterName =this.data[i].chapterName;
        let id = this.data[i].id;
        let data2 = {
          chapterName: chapterName,
          id: id
        };
        data.push(data2);
        this.saveAuxiliary(this.data[i].childList,data);
      }
      this.openLoadingView();
      this.$axios_supermall
        .put("/hnjxjy-core/eduCourseOutline/edit", data)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功");
            this.query();
          } else {
            this.$message.success("操作失败");
          }
        });
      this.loadingView.close();
    },
    back() {
      this.$router.push("/teacher/t/course/manage");
    },
    // 过程控制
    control(item) {
      this.controlSaveId = item.id;
      this.controlItem.radio = "0";
      this.controlItem.inputNumber = "0";
      if (item.processControl != null && item.processControl != 0) {
        this.controlItem.radio = "1";
        this.controlItem.inputNumber = item.processControl.toString();
      }
      this.controlItem.dialog = true;
    },
    controlSave() {
      let processControl = null;
      if (this.controlItem.radio == 1) {
        processControl = this.controlItem.inputNumber;
      }
      let data = {
        id: this.controlSaveId,
        processControl: processControl,
      };
      this.openLoadingView();
      this.$axios_supermall
        .put("/hnjxjy-core/eduCourseOutline/update", data)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success("操作成功");
            this.query();
          } else {
            this.$message.success("操作失败");
          }
        });
      this.controlItem.radio = "0";
      this.controlItem.inputNumber = "0";
      this.loadingView.close();
      this.controlItem.dialog = false;
    },
    controlCancel() {
      this.controlItem.radio = "0";
      this.controlItem.inputNumber = "0";
      this.controlItem.dialog = false;
    },

    // 课程资源查询
    selectionChange(val) {
      this.multipleSelection = val;
    },
    // 课程资源查询
    resourceQuery() {
      let formData = new FormData();
      formData.append("courseId", this.course.id);
      formData.append("page", "1");
      formData.append("pageSize", "10");
      if (this.resourceItem.search != null) {
        formData.append("resourceName", this.resourceItem.search.toString());
      }
      this.openLoadingView();
      this.$axios_supermallData
        .post("/hnjxjy-core/eduCourseResource/list", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            for (let i = 0 ; i < resp.data.data.list.length ; i++){
              resp.data.data.list[i].resourceSize =  parseFloat(resp.data.data.list[i].resourceSize / 1024 /1024).toFixed(2) + "M";
            }
            this.resourceItem.tableData = resp.data.data.list;
          }
        });
      this.loadingView.close();
    },
    // 课程资源
    resourceSave() {
      if (this.multipleSelection.length === 0) {
        return this.$message.error("请选择课程资源");
      }
      let dataArray = [];
      for (let v of this.multipleSelection) {
        let courseId = this.course.id;
        let chapterName = v.labelName;
        let teachingType = 1;
        let courseResourceId = v.id;
        let pchapterId = this.resourcePChapterId;
        let data = {
          courseId: courseId,
          chapterName: chapterName,
          pchapterId: pchapterId,
          teachingType: teachingType,
          courseResourceId: courseResourceId
        }
        dataArray.push(data);
      }
      this.openLoadingView();
      this.$axios_supermall
        .post("/hnjxjy-core/eduCourseOutline/addCell", dataArray)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success(resp.data.message);
            this.query();
          }
        });
      this.loadingView.close();
      this.resourceItem.search = null;
      this.resourceItem.tableData = [];
      this.resourceItem.dialog = false;
    },
    resourceDialog() {
      this.resourceItem.search = null;
      this.resourceItem.tableData = [];
      this.resourceItem.dialog = false;
    },
    // 外部链接
    linkSave(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;
        let dataArray = [];
        let courseId = this.course.id;
        let chapterName = this.linkItem.form.name.toString();
        let teachingType = 3;
        let teachingUrl = this.linkItem.form.url.toString();
        let pchapterId = this.resourcePChapterId;
        let data = {
          courseId: courseId,
          chapterName: chapterName,
          pchapterId: pchapterId,
          teachingType: teachingType,
          teachingUrl: teachingUrl
        }
        dataArray.push(data);
        this.openLoadingView();
        this.$axios_supermall
          .post("/hnjxjy-core/eduCourseOutline/addCell", dataArray)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.$message.success(resp.data.message);
              this.query();
            }
          });
        this.loadingView.close();
        this.linkItem.form = {};
        this.linkItem.dialog = false;
      });
    },
    linkDialog() {
      this.linkItem.form = {};
      this.linkItem.dialog = false;
    },
    onlineQuery() {
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduCourseWorkSetTea/queryEduCourseWorkSetByCourseId",
        method: "get",
        params: {
          courseId: this.course.id,
          page: this.onlineQueryParam.page,
          pageSize: this.onlineQueryParam.pageSize
        }
      })
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.onlineItem.tableData = resp.data.data.list;
            this.onlineQueryParamTotal = resp.data.data.total;
          }
        });
      this.loadingView.close();
    },

    //endregion
    // region 分页size
    handleSizeChangeDetails(val) {
      this.onlineQueryParam.pageSize = val;
      this.onlineQuery();
    }
    ,
    //endregion
    //region 分页Current
    handleCurrentChangeDetails(val) {
      this.onlineQueryParam.page = val;
      this.onlineQuery();
    },

    // 在线作业
    handleOnlineChange(row) {
      if (row) {
        this.onlineItem.row = row;
      }
      this.$message.info("您选择了" + row.workName);
    },
    // 在线作业 保存
    onlineSave() {
      // if (!this.onlineItem.row) return this.$message.error("请选择作业");
      if (!this.currentRow) return this.$message.error("请选择作业");
      let dataArray = [];
      let courseId = this.course.id;
      // let chapterName = this.onlineItem.row.workName.toString();
      let chapterName = this.currentRow.workName.toString();
      let teachingType = 2;
      // let workSetId = this.onlineItem.row.id;
      let workSetId = this.currentRow.id;
      let pchapterId = this.resourcePChapterId;
      let data = {
        courseId: courseId,
        chapterName: chapterName,
        pchapterId: pchapterId,
        teachingType: teachingType,
        workSetId: workSetId
      }
      dataArray.push(data);
      this.openLoadingView();
      this.$axios_supermall
        .post("/hnjxjy-core/eduCourseOutline/addCell", dataArray)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.$message.success(resp.data.message);
            this.query();
          }
        });
      this.currentRow = null;
      this.loadingView.close();
      this.onlineItem.dialog = false;
    },
    onlineItemBeforeClose(done){
      done();
      this.currentRow = null;
    },
    onlineItemDialogFalse(){
      this.currentRow = null;
      this.onlineItem.dialog = false;
    },
    // 添加主题
    addNodes(item, index) {
      this.addNodesId = item.id;
      // notsaved用于添加主题后在未保存的情况下进行删除不会调用接口，进行前端删除
      this.data[index].childList.push({ chapterName: "", notsaved: true });
    },
    addThemeSave() {},
    query() {
      let formData = new FormData();
      formData.append("courseId", this.course.id);
      this.openLoadingView();
      this.$axios_supermallData
        .post("/hnjxjy-core/eduCourseOutline/list", formData)
        .then((resp) => {
          if (resp.data.code == 2000) {
            this.data = resp.data.data;
          }
        });
      this.loadingView.close();
    },
    // 删除主题
    delNodes(item) {
      let formData = new FormData();
      formData.append("id", item.id);
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduCourseOutline/remove",
        method: "delete",
        params: {
          id: item.id,
        },
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.$message.success("操作成功");
          this.query();
        } else {
          this.$message.success("操作失败");
        }
      });
      this.loadingView.close();
    },
    // 添加单元
    addChild(item) {},
    // 添加单元
    command(item) {
      if (item.data.notsaved != undefined) {
        return this.$notify({
          title: "添加提示",
          message: "当前按钮之作用已存在的课程主题",
        });
      }
      this.resourcePChapterId = item.data.id;
      if (item.name === "课程资源") {
        this.resourceItem.dialog = true;
        setTimeout(() => {
          this.openLoadingView();
        }, 100);
        setTimeout(() => {
          this.resourceQuery();
          this.loadingView.close();
        }, 1000);
      } else if (item.name === "外部链接") {
        this.linkItem.dialog = true;
      } else if (item.name === "在线作业") {
        this.onlineItem.dialog = true;
        setTimeout(() => {
          this.openLoadingView();
        }, 100);
        setTimeout(() => {
          this.onlineQuery();
          this.loadingView.close();
        }, 1000);
      }else if(item.name === "网盘资源"){
        this.theCloud.dialog = true;
        setTimeout(() => {
          this.openLoadingView();
        }, 100);
        setTimeout(() => {
          this.theCloudQuery();
          this.loadingView.close();
        }, 1000);
      }
    },
    saveChild(item, index, parentIndex) {
      // 当前节点数据，当前节点索引，父索引
      if (item.notsaved == undefined) {
        let data = [{
          id: item.id,
          chapterName: item.chapterName
        }]
        this.openLoadingView();
        this.$axios_supermall
            .put("/hnjxjy-core/eduCourseOutline/edit", data)
            .then((resp) => {
              if (resp.data.code == 2000) {
                this.$message.success("操作成功");
                this.query();
              } else {
                this.$message.success("操作失败");
              }
            });
        this.loadingView.close();
      }else {
        let formData = new FormData();
        formData.append("courseId", this.course.id);
        formData.append("chapterName", item.chapterName);
        formData.append("pChapterId", this.addNodesId);
        this.openLoadingView();
        this.$axios_supermallData
            .post("/hnjxjy-core/eduCourseOutline/saveTopic", formData)
            .then((resp) => {
              if (resp.data.code == 2000) {
                this.$message.success("操作成功");
                this.addNodesId = null;
                this.query();
              } else {
                this.$message.success("操作失败");
              }
            });
        this.loadingView.close();
      }
    },
    // 删除主题及其下所有单元
    delAllChild(item, index, parentIndex) {
      // 未保存时删除
      if (item.notsaved) {
        this.data[parentIndex].childList.splice(index, 1);
        return;
      }
      // 删除
      this.delNodes(item);
    },
    // 删除单元
    delChild(item) {
      this.delNodes(item);
    },
    instantProblemSave(item) {
      this.instantOutlineItem = item;
      this.instant.dialog = true;
      setTimeout(() => {
        this.$refs.instant.resourceUrl = null;
        this.openLoadingView();
      }, 100);
      setTimeout(() => {
        this.$refs.instant.query();
        this.$refs.instant.InstantVideoInit();
        this.loadingView.close();
      }, 1000);
    },
    // 子级传来的数据
    instantData(data) {
    },
    // 即时题 确定按钮
    onInstantSave() {
      this.$refs.instant.tableData = [];
      this.$refs.instant.stop = false;
      this.$refs.instant.dialog = false;
      this.instant.dialog = false;
    },
    instantDialog() {
      this.$refs.instant.tableData = [];
      this.$refs.instant.stop = false;
      this.$refs.instant.dialog = false;
      this.instant.dialog = false;
    },
    beforeClose(done) {
      this.$refs.instant.tableData = [];
      this.$refs.instant.stop = false;
      this.$refs.instant.dialog = false;
      done();
    },
    theCloudButton(){
      this.theCloud.page = 1;
      this.theCloudQuery();
    },
    theCloudQuery(){
      this.openLoadingView();
      this.$axios_supermallData({
        url: '/hnjxjy-core/teacherCourse/queryAllEduNetDiskByTeacherId',
        method: 'get',
        params: {
          page: this.theCloud.page,
          pageSize: this.theCloud.pageSize,
          fileName: this.theCloud.search,
          pFileId: this.theCloud.pfileId
        }
      }).then((resp) => {
        if (resp.data.code === 2000) {
          this.theCloud.tableData = resp.data.data.list;
          this.theCloud.total = resp.data.data.total;
          this.loadingView.close();
        }else {
          this.loadingView.close();
        }
      }).catch(err => {
        this.$message.error(err)
        this.loadingView.close();
      })
    },
    theCloudSelectionChange(val){
      this.theCloud.selectionData = val;
    },
    theCloudDialog(){
      this.theCloud =  {
        page: 1,
        pageSize: 5,
        total: 0,
        dialog: false,
        search: null,
        selectionData: [],
        tableData: [],
        pfileId: null,
      };
      this.filePathChildData = [];
    },
    theCloudBeforeClose(done){
      this.theCloud =  {
        page: 1,
        pageSize: 5,
        total: 0,
        dialog: false,
        search: null,
        selectionData: [],
        tableData: [],
        pfileId: null,
      };
      this.filePathChildData = [];
      done();
    },
    theCloudSave(){
      if (this.theCloud.selectionData.length === 0){
        return this.$message.error("请选择资源")
      }
      let idArray = [];
      for (let i = 0 ; i < this.theCloud.selectionData.length ; i++){
        idArray.push(this.theCloud.selectionData[i].id);
      }
      // let data = {
      //   ids : idArray.toString(),
      //   pChapterId: this.resourcePChapterId,
      //   courseId: this.course.id
      // };
      let formData = new FormData();
      formData.append("ids",idArray.toString());
      formData.append("pChapterId",this.resourcePChapterId);
      formData.append("courseId",this.course.id);
      this.openLoadingView();
      this.$axios_supermallData.post("/hnjxjy-core/teacherCourse/addOutLineResourceByEduNetDisk",formData)
      .then(resp => {
        if (resp.data.code == 2000){
          this.$message.success("操作成功")
          this.query();
        }else {
          this.$message.error("操作失败")
          this.loadingView.close();
        }
      })
      .catch(err => {
        this.$message.error(err)
        this.loadingView.close();
      });
      this.theCloud =  {
        page: 1,
        pageSize: 5,
        total: 0,
        dialog: false,
        search: null,
        selectionData: [],
        tableData: [],
        pfileId: null,
      };
      this.filePathChildData = [];
    },
    theCloudSizeChange(val){
      this.theCloud.pageSize = val;
      this.theCloudQuery();
    },
    theCloudCurrentChange(val){
      this.theCloud.page = val;
      this.theCloudQuery();
    },
    actionPFileId(row){
      if(row.fileType === 0){
        this.theCloud.pfileId = row.id;
        if (this.filePathChildData) {
          this.filePathChildData.push({
            fileName: row.fileName,
            pfileId: row.id,
          });
        }
        this.theCloud.page = 1;
        this.theCloudQuery();
      }
    },
    checkBoxDisable(row){
      if (row.fileType === 0){
        return false;
      }else {
        return true;
      }
    },
    handleListRootNav(){
      this.theCloud.page = 1;
      this.theCloud.pfileId = null;
      this.filePathChildData = [];
      this.theCloudQuery();
    },
    rootNav(item, index){
      if (item) {
        this.filePathChildData.splice(index + 1, this.filePathChildData.length - index);
        this.theCloud.pfileId = item.pfileId;
        this.theCloud.page = 1;
        this.theCloudQuery();
      }
    },
  },
};
</script>
