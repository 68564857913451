<template>
  <el-row class="Add-Instant" :gutter="8">
    <el-col :sm="24" :md="10" class="group">
      <InstantVideo v-if="resourceUrl!=null" :resourceUrl="resourceUrl" @stop="handleStop" @play="handlePlay" />
    </el-col>
    <el-col :sm="24" :md="14">
      <el-table v-if="!stop" :data="tableData">
        <el-table-column label="日期" prop="createTime" align="center"></el-table-column>
        <el-table-column label="类型" prop="typeName" align="center"></el-table-column>
        <el-table-column label="名称" prop="questionTitle" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.typeName == '问答题'">
              <span v-html="scope.row.questionTitle"></span>
            </div>
            <div v-else>
              {{scope.row.questionTitle}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-else>
        <el-button @click="auditDrawer">从题库中选择试题</el-button>
        <el-button @click="confirm">确认</el-button>
        <el-checkbox
          style="margin-left: 10px"
          v-model="again"
          >答错后回放视频</el-checkbox
        >
        <div class="answer-items" v-if="rows">
          <div class="title" v-html="rows.questionTitle"></div>
          <!-- 单选题的话，打开这个v-if -->
          <ul
            class="radioItem"
            v-if="rows.eduCourseQuestionOps && rows.eduCourseQuestionOps.length"
          >
            <li v-for="(op, index) in rows.eduCourseQuestionOps" :key="index">
              <div class="itemKey">{{ op.itemKey }}）</div>
              <div class="itemVal">{{ op.itemVal }}</div>
            </li>
          </ul>
          <!-- 参考答案 -->
          <div class="reference_answer">
            <p class="name">参考答案</p>
            <p class="answer" v-html="rows.questionKey"></p>
          </div>
        </div>
      </div>
    </el-col>
    <!-- 测验题目 -->
    <el-dialog
      title="选择测验题目"
      :visible.sync="dialog"
      width="50%"
      append-to-body
    >
      <answer-list ref="AnswerList" @rows="getRows" />
    </el-dialog>
  </el-row>
</template>

<script>
import InstantVideo from "./InstantVideo.vue";
import AnswerList from "./AnswerList.vue";
export default {
  components: { InstantVideo, AnswerList },
  data() {
    return {
      resourceUrl: null,
      tableData: [],
      // 这个里面的假数据 对完接口，记得删掉，----------------------------
      rows: {
        questionTitle: null,
        eduCourseQuestionOps: [],
        questionKey: null,
      }, // 选中的测验题目
      stop: false, // 暂停，用于控制逻辑显示隐藏
      again: false, // 回放勾选
      dialog: false,
      timeStamp: null,
      questionId: null,
    };
  },
  props:{
    instantOutlineItem: {
      type:Object,
      default: null,
    }
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    },
  },
  methods: {
    InstantVideoInit(){
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseResource/selectEduCourseResourceById?id="+this.instantOutlineItem.courseResourceId)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.resourceUrl = resp.data.data.resourceUrl;
            }
          });
      this.loadingView.close();
    },
    query(){
      this.openLoadingView();
      let formData = new FormData();
      formData.append("courseId",this.course.id);
      formData.append("outlineId",this.instantOutlineItem.id)
      this.$axios_supermallData.post("/hnjxjy-core/eduInstantQuestion/list",formData)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.tableData = resp.data.data.list;
            }
          });
      this.loadingView.close();
    },
    // 选中的测验题目
    getRows(row) {
      this.openLoadingView();
      this.dialog = false;
      this.questionId = row.id;
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseQuestion/queryEduCourseQuestionById?id="+row.id)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.rows = resp.data.data;
            }
          });
      this.loadingView.close();
    },
    // 播放
    handlePlay(player) {
      this.stop = false;
    },
    // 暂停
    handleStop(player) {
      this.timeStamp = player.cache_.currentTime;
      this.rows = {};
      this.stop = true;
    },
    // 答错后回访
    // checkboxChange(val) {
    // },
    // 确认
    confirm() {
      this.openLoadingView();
      let isPlayback = 0;
      if (this.again == true){
        isPlayback = 1;
      }
      let timeStamp = parseInt(this.timeStamp);
      let formData = new FormData();
      formData.append("courseId",this.course.id);
      formData.append("outlineId",this.instantOutlineItem.id)
      formData.append("questionId",this.questionId)
      formData.append("isPlayback",isPlayback.toString())
      formData.append("timeStamp",timeStamp.toString())
      this.$axios_supermallData.post("/hnjxjy-core/eduInstantQuestion/add",formData)
          .then((resp) => {
            if (resp.data.code == 2000) {
              this.$message.success("添加成功")
              this.query()
            }else {
              this.$message.error("添加失败")
            }
          });
      this.stop = false;
      this.loadingView.close();
    },
    auditDrawer(){
      this.dialog = true;
      setTimeout(()=>{
        this.openLoadingView();
      },100)
      setTimeout(()=>{
        this.$refs.AnswerList.init();
        this.$refs.AnswerList.initTypeOptions();
        this.loadingView.close();
      },1000)
    },
    handleDelete(row){
      this.openLoadingView();
      this.$axios_supermallData({
        url: "/hnjxjy-core/eduInstantQuestion/remove",
        method: 'delete',
        params: {
          ids: row.id
        }
      }).then((resp) => {
        if (resp.data.code == 2000) {
          this.$message.success("删除成功")
          this.query();
        }else {
          this.$message.success("删除失败")
        }
      });
      this.loadingView.close();
    },
  },
};
</script>

<style scoped lang="less">
.Add-Instant {
  .answer-items {
    border: 1px solid @borderBottomColor;
    padding: 10px;
    margin-top: 10px;
    font-size: 14px;
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .radioItem {
      li {
        margin-bottom: 20px;
        div {
          display: inline-block;
        }
      }
    }
    // 参考答案
    .reference_answer {
      background-color: #f9f9f9;
      padding: 15px;
      border: 1px dashed #d8d8d8;
      overflow: hidden;
      color: #999;
      margin-top: 20px;
      .name {
        margin-bottom: 10px;
        color: #099;
      }
      .answer {
      }
    }
    .parse {
      padding: 15px;
      .name {
        margin-bottom: 10px;
        color: #099;
        font-weight: 500;
      }
    }
  }
}
</style>
